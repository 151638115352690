import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Heading from "../../elements/Heading";
import { Container, ImagesContainer, ImageContainer } from "./styles";

const Gallery = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container id="gallery">
      <Heading t={t} />
      <ImagesContainer>
        <ImageContainer>
          <StaticImage
            src="../../../images/gallery/1.jpg"
            alt={t.photoAlt}
            placeholder="blurred"
            layout="constrained"
            tw="object-cover object-center h-80 lg:max-h-full hover:lg:scale-125 transition duration-500 ease-in-out"
          />
        </ImageContainer>
        <ImageContainer>
          <StaticImage
            src="../../../images/gallery/2.jpg"
            alt={t.photoAlt}
            placeholder="blurred"
            layout="constrained"
            tw="object-cover object-center h-80 lg:max-h-full hover:lg:scale-125 transition duration-500 ease-in-out"
          />
        </ImageContainer>
        <ImageContainer>
          <StaticImage
            src="../../../images/gallery/3.jpg"
            alt={t.photoAlt}
            placeholder="blurred"
            layout="constrained"
            tw="object-cover object-center h-80 lg:max-h-full hover:lg:scale-125 transition duration-500 ease-in-out"
          />
        </ImageContainer>
        <ImageContainer>
          <StaticImage
            src="../../../images/gallery/4.jpg"
            alt={t.photoAlt}
            placeholder="blurred"
            layout="constrained"
            tw="object-cover object-center h-80 lg:max-h-full hover:lg:scale-125 transition duration-500 ease-in-out"
          />
        </ImageContainer>
        <ImageContainer>
          <StaticImage
            src="../../../images/gallery/5.jpg"
            alt={t.photoAlt}
            placeholder="blurred"
            layout="constrained"
            tw="object-cover object-center h-80 lg:max-h-full hover:lg:scale-125 transition duration-500 ease-in-out"
          />
        </ImageContainer>
        <ImageContainer>
          <StaticImage
            src="../../../images/gallery/6.jpg"
            alt={t.photoAlt}
            placeholder="blurred"
            layout="constrained"
            tw="object-cover object-center h-80 lg:max-h-full hover:lg:scale-125 transition duration-500 ease-in-out"
          />
        </ImageContainer>
      </ImagesContainer>
    </Container>
  );
};

export default Gallery;
