import tw from "twin.macro";

export const Container = tw.section`
  text-gray-600 relative container mx-auto flex flex-wrap
  md:flex-nowrap
`;

export const MapContainer = tw.div`
  w-full overflow-hidden p-4 flex items-end justify-start relative h-96 bg-gray-300
  md:h-auto
  lg:w-3/5
`;

export const FormContainer = tw.form`
  flex flex-col w-full px-4 py-4 bg-white
  md:py-0 md:px-6
  lg:w-2/5
`;

export const Submit = tw.button`
    rounded text-lg border-0 py-2 mt-6 mb-4 px-6 text-white bg-primary
    focus:outline-none 
    hover:bg-gray-600
    disabled:bg-gray-400
`;

export const Success = tw.div`
  text-xl text-primary text-center pb-3  whitespace-pre
`;

export const Error = tw.div`
  text-lg text-red-400 text-center whitespace-pre pt-4 pb-2
`;

export const FieldError = tw.div`
  text-sm text-red-400 pt-1
`;

export const Label = tw.label`
  leading-7 text-sm text-gray-600 pb-2 block
`;

export const InputTw = tw.input`
  w-full bg-white rounded border border-gray-300 focus:border-primary focus:ring-2 focus:ring-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out
`;

export const Textarea = tw.textarea`
  w-full bg-white rounded border border-gray-300 focus:border-primary focus:ring-2 focus:ring-primary h-24 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out
`;

export const Select = tw.select`
  bg-white w-full h-10 rounded border border-gray-300 focus:border-primary focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out
`;
