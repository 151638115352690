import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styles, {
  Container,
  TextContainer,
  Title,
  Description,
  ImageContainer,
} from "./styles";

const AboutUs = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container id="about">
      <ImageContainer>
        <StaticImage
          src="../../../images/about.jpg"
          alt={t.imageAlt}
          placeholder="blurred"
          layout="constrained"
          css={styles.image}
        />
      </ImageContainer>
      <TextContainer>
        <Title
          data-sal="slide-down"
          data-sal-delay="100"
          data-sal-duration="600"
          data-sal-easing="ease-in-out"
        >
          {t.title}
        </Title>
        <Description>{t.description}</Description>
      </TextContainer>
    </Container>
  );
};

export default AboutUs;
