import { useState } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
  FormContainer,
  Submit,
  Success,
  Error,
  FieldError,
  InputTw,
  Select,
  Textarea,
  Label,
} from "./styles";
import tw from "twin.macro";

export default function FormComponent({ t }: { t: Record<string, string> }) {
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values: any) => {
    const toSend = `entry.2005620554=${values.name}&entry.1045781291=${values.email}&entry.1664432647=${values.lessons}&entry.839337160=${values.message}&submit=Submit`;

    try {
      const response = await fetch(
        `https://docs.google.com/forms/u/0/d/e/1FAIpQLSeBYpWqMArvcxA_d9eSFy3BpQI90TgNnEOVgA4DEhaJDrkANw/formResponse?embedded=true&${toSend}`,
        { credentials: "include", mode: "no-cors" }
      );
      console.log({ response });
      setSuccess(true);
    } catch (error: any) {
      console.log({ error });
      return { [FORM_ERROR]: t.requiredError };
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values: any) => {
        const errors = {} as any;

        if (!values.name) {
          errors.name = "Required";
        }
        if (!values.email) {
          errors.email = "Required";
        }
        if (!values.message) {
          errors.message = "Required";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, submitError }: any) => (
        <FormContainer onSubmit={handleSubmit}>
          <h1
            data-sal="slide-down"
            data-sal-delay="100"
            data-sal-duration="600"
            data-sal-easing="ease-in-out"
            tw="text-gray-900 text-5xl my-10 font-bold text-center"
          >
            {t.title}
          </h1>

          <Field
            name="name"
            validate={(value: string) => (!value ? t.errorName : undefined)}
          >
            {({ input, meta }: any) => (
              <div tw="relative mb-4">
                <Label>{t.nameLabel}</Label>
                <InputTw
                  {...input}
                  type="text"
                  disabled={success}
                  css={
                    meta.error && meta.touched
                      ? tw`border-red-400 ring-1 ring-red-400`
                      : null
                  }
                />
                {meta.error && meta.touched && (
                  <FieldError>{meta.error}</FieldError>
                )}
              </div>
            )}
          </Field>
          <Field
            name="email"
            validate={(value: string) => (!value ? t.errorEmail : undefined)}
          >
            {({ input, meta }: any) => (
              <div tw="relative mb-4">
                <Label>{t.emailLabel}&nbsp;*</Label>
                <InputTw
                  {...input}
                  type="email"
                  disabled={success}
                  css={
                    meta.error && meta.touched
                      ? tw`border-red-400 ring-1 ring-red-400`
                      : null
                  }
                />
                {meta.error && meta.touched && (
                  <FieldError>{meta.error}</FieldError>
                )}
              </div>
            )}
          </Field>
          <Field name="lessons">
            {({ input }: any) => (
              <div tw="relative mb-4">
                <Label>{t.lessonsLabel}</Label>
                <Select {...input} disabled={success}>
                  {[
                    {
                      value: "",
                      text: t.optionDefault,
                    },
                    {
                      value: "Grupo",
                      text: t.optionGroup,
                    },
                    {
                      value: "Privada",
                      text: t.optionPrivate,
                    },
                    {
                      value: "Pack3",
                      text: t.optionPack3,
                    },
                    {
                      value: "Pack6",
                      text: t.optionPack6,
                    },
                    {
                      value: "Pack10",
                      text: t.optionPack10,
                    },
                    {
                      value: "Pack12",
                      text: t.optionPack12,
                    },
                  ].map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </Select>
              </div>
            )}
          </Field>

          <Field
            name="message"
            validate={(value: string) => (!value ? t.errorMessage : undefined)}
          >
            {({ input, meta }: any) => (
              <div tw="relative mb-4">
                <Label>{t.messageLabel}</Label>
                <Textarea
                  {...input}
                  disabled={success}
                  css={
                    meta.error && meta.touched
                      ? tw`border-red-400 ring-1 ring-red-400`
                      : null
                  }
                />
                {meta.error && meta.touched && (
                  <FieldError>{meta.error}</FieldError>
                )}
              </div>
            )}
          </Field>
          {!success && (
            <Submit type="submit" disabled={submitting}>
              {submitting ? t.sending : t.button}
            </Submit>
          )}
          {submitError && <Error>{t.error}</Error>}
          {success && <Success>{t.success}</Success>}
        </FormContainer>
      )}
    />
  );
}
