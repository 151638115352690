import tw from "twin.macro";

export const Container = tw.section`
  px-4 py-8 flex flex-col items-center gap-10 mx-auto
  md:px-6
`;

export const ImageContainer = tw.div`
  flex flex-wrap justify-center py-4
`;
