import * as React from "react";
import { Container, MapContainer } from "./styles";
import Form from "./Form";

const Contacts = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container id="contacts">
      <MapContainer>
        <iframe
          loading="lazy"
          width="100%"
          height="100%"
          tw="absolute inset-0"
          title="map"
          scrolling="no"
          src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=Praia%20da%20Mata,%20Caparica&ie=UTF8&iwloc=B&output=embed"
          style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
        />

        <div tw="bg-white relative py-6 rounded shadow">
          <h2 tw="font-bold text-gray-900 text-2xl lg:text-3xl px-6 pb-6">
            {t.contacts}
          </h2>
          <div tw="flex flex-wrap">
            <div tw="lg:w-1/2 px-6">
              <h2 tw="font-semibold text-gray-900 tracking-widest text-sm uppercase">
                {t.addressLabel}
              </h2>
              <a
                href="https://maps.google.com/maps?q=praia%20da%20mata&t=k&z=13&ie=UTF8"
                target="_blank"
                rel="noopener noreferrer nofollow"
                tw="mt-1 whitespace-pre text-primary leading-relaxed"
              >
                {t.address}
              </a>
            </div>
            <div tw="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 tw="font-semibold text-gray-900 tracking-widest text-sm uppercase">
                {t.emailLabel}
              </h2>
              <a href={`mailto:${t.email}`} tw="text-primary leading-relaxed">
                {t.email}
              </a>
              <h2 tw="font-semibold text-gray-900 tracking-widest text-sm uppercase mt-4">
                {t.phoneLabel}
              </h2>
              <a href={`tel:${t.phone}`} tw="text-primary leading-relaxed">
                {t.phone}
              </a>
            </div>
          </div>
        </div>
      </MapContainer>
      <Form t={t} />
    </Container>
  );
};

export default Contacts;
