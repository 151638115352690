import * as React from "react";
import { Container, Title, Description } from "./styles";

const Heading = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container>
      <Title
        data-sal="slide-down"
        data-sal-delay="100"
        data-sal-duration="600"
        data-sal-easing="ease-in-out"
      >
        {t.title}
      </Title>
      {t.description && <Description>{t.description}</Description>}
    </Container>
  );
};

export default Heading;
