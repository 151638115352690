import tw from "twin.macro";

export const Container = tw.section`
  container mx-auto pt-14
`;

export const ImagesContainer = tw.div`
  flex flex-wrap cursor-[zoom-in] pt-4
`;
export const ImageContainer = tw.div`
  w-full overflow-hidden
  md:w-1/2
  lg:w-1/3
`;
