import tw from "twin.macro";

export const Container = tw.section`
    w-full h-screen
`;

export const Overlay = tw.div`
    relative w-full h-full bg-opacity-10 bg-black flex justify-center items-center z-10
`;

export const TextContainer = tw.div`
    mx-4 text-center text-white
`;

export const Title = tw.h1`
    mb-4 font-bold text-5xl
    sm:text-6xl
    xl:text-7xl
`;

export const Description = tw.div`
    mb-12 mx-auto text-xl
    sm:text-2xl
    md:w-2/3
    xl:text-3xl
`;

export const PrimaryCta = tw.a`
    bg-primary inline-flex items-center justify-center px-4 py-2 shadow rounded
    text-lg whitespace-nowrap text-white
    sm:text-xl
    xl:text-2xl
    hover:bg-white hover:text-primary
    transition duration-500 ease-in-out
`;

export const SecondaryCta = tw.a`
    ml-4 bg-gray-600 inline-flex items-center justify-center px-4 py-2 shadow rounded
    text-lg whitespace-nowrap text-white
    sm:text-xl
    md:ml-8
    xl:text-2xl
    hover:bg-white hover:text-gray-600
    transition duration-500 ease-in-out
`;

const styles = {
  video: tw`bg-fixed absolute w-full h-screen object-cover z-0 top-0`,
};

export default styles;
