import tw from "twin.macro";

export const Container = tw.section`
  mx-auto flex flex-col
  lg:flex-row
`;

export const ImageContainer = tw.div`
  w-full
  lg:w-1/2
`;

export const TextContainer = tw.div`
  px-4 py-14 flex flex-col items-center mx-auto
  md:px-6 md:py-12
  lg:px-10 lg:items-start lg:w-1/2 lg:mx-0
  xl:px-16 xl:max-w-3xl
`;

export const Title = tw.h2`
  mb-6
  text-5xl font-bold
`;

export const Description = tw.p`
  leading-relaxed whitespace-pre-wrap
  xl:text-xl
`;

const styles = {
  image: tw`
  object-cover object-center max-h-80
  lg:max-h-[400px]
`,
};

export default styles;
