import * as React from "react";
import { Container, ImageContainer } from "./styles";
import { StaticImage } from "gatsby-plugin-image";

const Cta = () => {
  return (
    <Container>
      <ImageContainer>
        <StaticImage
          src="../../../images/clean-safe.png"
          alt="clean and safe"
          placeholder="blurred"
          layout="constrained"
          objectFit="cover"
          tw="md:max-w-lg"
        />
      </ImageContainer>
    </Container>
  );
};

export default Cta;
