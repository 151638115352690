import * as React from "react";
import videoSrc from "../../../images/video2.mp4";
import posterSrc from "../../../images/poster.webp";
import styles, {
  Container,
  TextContainer,
  Overlay,
  Title,
  Description,
  PrimaryCta,
  SecondaryCta,
} from "./styles";

const Hero = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container id="home">
      <video
        poster={posterSrc}
        css={styles.video}
        preload="metadata"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      <Overlay>
        <TextContainer>
          <Title className="text-shadow">
            <div>{t.title}</div>
            <div>{t.subtitle}</div>
          </Title>
          <Description>
            <span className="text-shadow">{t.description}</span>
          </Description>
          <PrimaryCta href="#contacts">{t.cta}</PrimaryCta>
          <SecondaryCta href="#about">{t.knowMore}</SecondaryCta>
        </TextContainer>
      </Overlay>
    </Container>
  );
};

export default Hero;
