import * as React from "react";
import { Container, Title, Button } from "./styles";

const Cta = ({ t }: { t: Record<string, string> }) => {
  return (
    <Container>
      <Title>{t.title}</Title>
      <Button
        href="#contacts"
        data-sal="slide-up"
        data-sal-delay="100"
        data-sal-duration="600"
        data-sal-easing="ease-in-out"
      >
        {t.cta}
      </Button>
    </Container>
  );
};

export default Cta;
